import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 17 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7.667 11.5h1.666v1.666H7.667zm0-6.667h1.666v5H7.667zM8.492.667C3.892.667.167 4.4.167 9s3.725 8.333 8.325 8.333c4.608 0 8.341-3.733 8.341-8.333S13.1.667 8.492.667m.008 15A6.665 6.665 0 0 1 1.833 9 6.665 6.665 0 0 1 8.5 2.333 6.665 6.665 0 0 1 15.166 9 6.665 6.665 0 0 1 8.5 15.666"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "alert-circle"}) } }